import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import styles
import "../styles/blog-post.scss"

const Post = ({ data, location }) => {
  const post = data.ghostPost

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />

      <div className="post-view post-view--legal">
        <article
          className={
            "wrapper-800" +
            (post.feature_image == null ? " no-feature-image" : "")
          }
        >
          <header className="post__header">
            {/* Title */}
            <h1 className="post__title">{post.title}</h1>
          </header>

          {/* Post Content */}
          <div className="post__body">
            <div
              className="post-full-content load-external-scripts"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default Post

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
